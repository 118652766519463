import React, { useEffect } from "react";
import { useState } from "react";
import CreateMarshal from "./CreateMarshal";
import configData from "../../config.json";

import Modal from "react-modal";
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import Table from "../common/Table";
import { HiOutlineSearch } from "react-icons/hi";
import ModalContainer from "../common/ModalContainer";
import AddNewReport from "../Forms.js/AddNewReport";
import AddMarshal from "../Forms.js/AddMarshal";
import { getAllMarshals } from "../../utils/marshals/getAllMarshal";
import ConfirmationModal from "../common/ConfirmationModal";
import { deleteReport } from "../../utils/reports/deleteReport";
import { deleteMarshal } from "../../utils/marshals/deleteMarshal";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";

function ManageMarshalls() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    // getAllMarshals,
    getAllUnits,
    getRoles,
    marshall,
    setMarshall,
    loadingSm, 
    setLoadingSm,
    units,
    filterMarshalByUnitId,
    auth,
    userRoles,
  } = useAuth();
  // useEffect(() => {
  //   setLoading(true);
  //   getAllMarshals()
  //     .then((res) => {
  //       setLoading(false);
  //       setMarshall(res?.data?.data);
  //     })
  //     .catch(() => {
  //       console.log("error fetching marshals");
  //     });
  //   getAllUnits();
  //   getRoles();
  // }, []);

  const unitId = auth?.userDetails?.unitId;
  // console.log(auth);

  useEffect(() => {
    setLoading(true);
    if (auth?.roles === "UNITCOORDINATOR") {
      filterMarshalByUnitId(unitId);
      setLoading(false);
    } else {
      getAllMarshals()
        .then((res) => {
          setLoading(false);
          setMarshall(res?.data?.data);
        })
        .catch(() => {
          console.log("error fetching marshals");
        });
    }
    getAllUnits();
    getRoles();
  }, []);

  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [openModal, setOpenModal] = useState({
    modalName: "",
    showModal: false,
  });
  // const [rowData, setRowData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  // const filterMarshalByUnit = (unit) => {
  //   setSelectedUnit(unit);
  //   if (selectedUnit !== "") {
  //     filterMarshalByUnitId(unit);
  //   } else {
  //     getAllMarshals();
  //   }
  // };

  const filterMarshalByUnit = (unit) => {
  setSelectedUnit(unit);
  if (unit !== null && unit !== "") {
    filterMarshalByUnitId(unit);
  } else {
    getAllMarshals()
        .then((res) => {
          setLoading(false);
          setMarshall(res?.data?.data);
        })
        .catch(() => {
          console.log("error fetching marshals");
        });
  }
};

  // const UnitName = rowData?.Unit?.Name;
  // const marshallinThisUnit = marshall.filter((item) => item.unit === UnitName);

  // auth?.roles === "UnitCoordinator" && (setMarshall(marshallinThisUnit))

  // console.log("user", userRoles);
  const navigate = useNavigate();
  const handleMarshalDelete = () => {
    setLoading(true);
    deleteMarshal(rowData?.id)
      .then((res) => {
        setLoading(false);
        setOpenModal({ modalName: "", showModal: false });
        toast.success("Marshal deleted successfully");
        if (auth?.roles === "UNITCOORDINATOR") {
          filterMarshalByUnitId(unitId);
          setMarshall(res?.data?.data);
        } else {
          getAllMarshals()
            .then((res) => {
              setMarshall(res?.data?.data);
            })
            .catch(() => {
              console.log("error fetching marshals");
              setLoading(false);
              navigate("/login")
            });
        }
      })
      .catch((err) => {
        console.log("error deleting report", err);
        setLoading(false);
      });
  };
  return (
    <div className="min-h-[92vh]">
      <div className="flex flex-col lg:flex-row justify-between items-center mb-[30px]">
        <div className="relative flex flex-col lg:flex-row gap-[21px] items-center">
          <div className="relative">
            <input
              className="w-[343px] h-[45px] rounded-[8px]  pl-[36px] pr-4 text-[16px] relative outline-none"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <span className="absolute top-[14px] left-[15px] text-[20px]">
              <HiOutlineSearch />
            </span>
          </div>
          {auth?.roles === "ADMINISTRATOR" && (
            <select
              className="rounded-[8px] border h-[45px] px-4 w-full lg:w-[177px] border-[#A6A6A6] outline-none"
              value={selectedUnit}
              onChange={(e) => filterMarshalByUnit(e.target.value)}
            >
              <option value="" selected>
                Select Unit
              </option>
              {units?.map((unit) => (
                <option key={unit.id} value={unit.id}>
                  {unit.name}
                </option>
              ))}
            </select>
          )}
          {auth?.roles === "ADMINISTRATOR" && (
            <select
              className="rounded-[8px] border h-[45px] px-4 w-full lg:w-[177px] border-[#A6A6A6] outline-none"
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
            >
              <option value="" selected>
                Select Role
              </option>
              {/* <option value={false} selected>
                unVerified
              </option> */}
              {userRoles?.map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </select>
          )}
        </div>
        <button
          className="rounded-[8px] bg-[#0D6EFD] text-[16px] text-white px-3 py-[10px] flex items-center gap-2 mt-[21px] lg:mt-0"
          onClick={() => setModalIsOpen(true)}
        >
          Add a New Marshal <span className="text-[28px] font-semibold">+</span>
        </button>
      </div>
      <Table
        state={loading}
        header={[
          { name: "First Name", identifier: "firstName" },
          { name: "Last Name", identifier: "lastName" },
          { name: "S/M Number", identifier: "smNumber" },
          { name: "Email Address", identifier: "emailAddress" },
          // { name: "Phone Number", identifier: "alternativeNumber" },
          // { name: "Status", identifier: "Active" },
        ]}
        data={[...marshall]?.filter((user) =>
          selectedRole ? user?.userRole === selectedRole : user
        )}
        options={{
          variant: "primary",
          tableStyle: "",
          allowSorting: true,
          allowActions: true,
          actions: {
            edit: true,
            view: true,
            delete: true,
            reset: true,
          },
          showPagination: true,
        }}
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
        setRowData={setRowData}
        searchQuery={searchQuery}
      />
      {modalIsOpen && (
        <ModalContainer>
          <AddMarshal loading={loading} closeModal={setModalIsOpen} />
        </ModalContainer>
      )}
      {openModal.showModal && openModal.modalName === "edit" && (
        <ModalContainer>
          <AddMarshal
            loading={loading}
            mode="edit"
            closeModal={setOpenModal}
            rowData={rowData}
          />
        </ModalContainer>
      )}
      {openModal.showModal && openModal.modalName === "view" && (
        <ModalContainer>
          <AddMarshal
            loading={loading}
            mode="view"
            closeModal={setOpenModal}
            rowData={rowData}
          />
        </ModalContainer>
      )}
      {openModal.showModal && openModal.modalName === "delete" && (
        <ModalContainer height="auto">
          <ConfirmationModal
            message="Are you sure you want to delete this user?"
            handleCancel={() =>
              setOpenModal({ modalName: "", showModal: false })
            }
            handleConfirm={handleMarshalDelete}
            loading={loading}
          />
        </ModalContainer>
      )}
      {openModal.showModal && openModal.modalName === "reset" && (
        <ModalContainer height="auto">
          <PasswordResetView
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </ModalContainer>
      )}
    </div>
  );
}

export default ManageMarshalls;

const PasswordResetView = ({ openModal, setOpenModal }) => {
  return (
    <form className="p-10">
      <div className="mb-5">
        <label className="font-semibold text-[16px] mb-[8px]">
          New Password
        </label>
        <input
          className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
          name="password"
          type="password"
          // value={formData.smNumber}
          // onChange={handleChange}
          required
          autoComplete="new-password"
        />
      </div>
      <div className="mb-5">
        <label className="font-semibold text-[16px] mb-[8px]">
          Confirm New Password
        </label>
        <input
          className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
          name="confirmPassword"
          type="password"
          // value={formData.smNumber}
          // onChange={handleChange}
          required
          autoComplete="new-password"
        />
      </div>
      <div className="flex items-center justify-center gap-5 w-full">
        <button
          className="outline-none py-4 rounded-md border w-1/2 text-[15px]"
          onClick={() => setOpenModal({ modalName: "", showModal: false })}
          type="button"
        >
          Cancel
        </button>
        <button className="outline-none py-4 rounded-md w-1/2 bg-[#0D6EFD] text-white text-[15px]">
          Reset
        </button>
      </div>
    </form>
  );
};
