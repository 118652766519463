import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate} from "react-router-dom";

// import "./index.css";

import MainLayout from "./components/Layouts/MainLayout";

import Home from "./pages/Home";
import RequireAuth from "./components/ProtectedRoutes/RequireAuth";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Error from "./pages/Error";
import Unauthorized from "./components/Unauthorized";
import SMLayout from "./components/Layouts/SMLayout";
import SMDashboard from "./components/Dashboard/SMDashboard";
import SMReports from "./components/Dashboard/SMReports";
import StateProvider from "./context/AppStates";
import AdminDashboard from "./components/AdminDashBoard/AdminDashboard";
import AdminReports from "./components/AdminDashBoard/AdminReports";
import AdminLayout from "./components/Layouts/AdminLayout";
import ManageMarshalls from "./components/AdminDashBoard/ManageMarshall";
import ManageMarshallsReports from "./components/AdminDashBoard/MarshallReports";
import RequireSpecialAuth from "./components/ProtectedRoutes/RequireSpecialAuth";
import LookupPage from "./pages/Lookup";
import LoginAuth from "./components/ProtectedRoutes/LoginRoute";
import AppraisalPage from "./components/AdminDashBoard/AppraisalPage";
import AppraisalPage2 from "./components/AdminDashBoard/AppraisalPage2";
import ResetPassword from "./pages/ResetPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DocumentsPage from "./pages/DocumentsPage";
import ManualUploads from "./components/ManualUpload";
import axios from "axios";
import configData from "./config.json";
import UnitsPage from "./pages/UnitsPage";
import SystemLogsPage from "./pages/SystemLogsPage";
import Signup from "./components/signup";
import ManageUnits from "./components/AdminDashBoard/manageUnits";
import MarshalDocumentsPage from "./pages/MarshalDocumentsPage";
import ManualsPage from "./pages/manualsPage";
import ProfilePage from "./pages/profile-page";
import ManageMarshalReportsPage from "./pages/ManageMarshalReportsPage";
import BookingsPage from "./pages/Bookings";
import OffencesPage from "./pages/OffencesPage";
import CategoryPage from "./pages/categoryPage";
import { getMarshalProfile } from "./utils/marshals/getMyprofile";
// import Manual from "./components/AdminDashBoard/manual";

function App() {
  // const navigate = useNavigate();
  useEffect(() => {
    const fetchLinks = async () => {
      await axios
        .get(configData.links.api + "api/v1/Links/getLinks")
        .then((res) => {
          window.localStorage.setItem("ApiUrl", configData.links.api);
          // console.log(res.data.apiUrl)
        });
    };
    fetchLinks();
  }, []);

  // useEffect(() => {
  //   getMarshalProfile()
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching marshal profile:", error);
  //       navigate("/login");
  //     });
  // }, [navigate]);

    
  return (
    <StateProvider>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
          </Route>
          <Route element={<LoginAuth />}>
            <Route path="login" element={<Login />} />
          </Route>
          <Route element={<LoginAuth />}>
            <Route path="signup" element={<Signup />} />
          </Route>
          <Route path="lookup" element={<LookupPage />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="ResetPassword" element={<ResetPassword />} />
          {/* <Route element={<RequireAuth allowedRoles={"SpecialMarshal"} />}>
            <Route path="dashboard" element={<SMLayout />}>
              <Route index element={<SMDashboard />} />
              <Route path="/dashboard/reports" element={<SMReports />} />
            </Route>
          </Route> */}
          <Route path="dashboard" element={<AdminLayout />}>
            <Route index element={<AdminDashboard />} />
            <Route path="/dashboard/reports" element={<AdminReports />} />
            <Route element={<RequireSpecialAuth />}>
              <Route
                path="/dashboard/all-marshalls"
                element={<ManageMarshalls/>}
              />
              <Route
                path="/dashboard/my-documents"
                element={<DocumentsPage />}
              />
              <Route
                path="/dashboard/marshal-documents"
                element={<MarshalDocumentsPage />}
              />
              <Route path="/dashboard/manage-units" element={<ManageUnits />} />
              <Route path="/dashboard/manuals" element={<ManualsPage />} />
              <Route path="/dashboard/profile" element={<ProfilePage />} />
              <Route path="/dashboard/bookings" element={<BookingsPage />} />
              <Route path="/dashboard/offences" element={<OffencesPage />} />
              <Route path="/dashboard/category" element={<CategoryPage/>} />
              <Route
                path="/dashboard/manage-reports"
                element={<ManageMarshalReportsPage />}
              />
              {/* <Route
                path="/admin/appraise-marshal/:smId"
                element={<AppraisalPage />}
              />
              <Route
                path="/admin/appraise-marshal/"
                element={<AppraisalPage2 />}
              />
              <Route
                path="/admin/units"
                element={<UnitsPage/>}
              />
              <Route
                path="/admin/system-logs"
                element={<SystemLogsPage/>}
              />
              <Route path="manuals" element={<ManualUploads />} /> */}
            </Route>
          </Route>
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    </StateProvider>
  );
}

export default App;
