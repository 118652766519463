import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FiUpload } from "react-icons/fi";
import axios from "axios";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { getAllRoles } from "../../utils/roles/getRoles";
import { getUnits } from "../../utils/units/getUnits";
import { el } from "date-fns/locale";

const RegistrationForm = ({step, setStep}) => {
 
  const { ApiUrl, setAuth, adminRoles, setAdminRoles, setUserRole } = useAuth();
  const [userData, SetUserData] = useState({})
  const SIGNUP_URL = ApiUrl + "/api/v1/Account/register";
  const navigate = useNavigate();
  

  const [signatureFileitem, setSignatureFileFileitem]=useState()
  const [passportFileFileitem, SetpassportFileFileitem] = useState()
  const [passportFile, SetpassportFile] = useState(null);
const [signatureFile, setSignatureFile] = useState(null);
const [roles, SetRoles] = useState()
const [unit, setUnit]= useState()

// console.log(passportFile)
useEffect(() => {
  getAllRoles()
    .then((roles) => {
      SetRoles(roles?.data?.data)
    })
    .catch((error) => {
      console.error("Error fetching roles:", error);
    });
}, [])

useEffect(() => {
  getUnits()
    .then((roles) => {
      setUnit(roles?.data?.data)
    })
    .catch((error) => {
      console.error("Error fetching roles:", error);
    });
}, [])

console.log(unit)

  const validationSchema = Yup.object().shape({
    // Step 1 Validation
    firstName: step === 1 ? Yup.string().required("First name is required") : null,
    lastName: step === 1 ? Yup.string().required("Last name is required") : null,
    // middleName: step === 1 ? Yup.string() : null,
    // userName: step === 1 ? Yup.string().required("Username is required") : null,
    gender: step === 1 ? Yup.string().required("Gender is required") : null,
    dateOfBirth: step === 1 ? Yup.date().required("Date of Birth is required") : null,
    password: step === 1
      ? Yup.string().min(6, "Password must be at least 6 characters").required("Password is required")
      : null,
    confirmPassword: step === 1
      ? Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm Password is required")
      : null,

    // Step 2 Validation
    email: step === 2 ? Yup.string().email("Invalid email").required("Email is required") : null,
    phoneNo: step === 2 ? Yup.string().required("Phone number is required") : null,
    // alternativePhone: step === 2 ? Yup.string() : null,
    profession: step === 2 ? Yup.string().required("Profession is required") : null,
    address: step === 2 ? Yup.string().required("Address is required") : null,
    // userType: step === 2 ? Yup.string().required("User type is required") : null,
    // paidStatus: step === 2 ? Yup.boolean().required("Paid status is required") : null,

    // Step 3 Validation
    // userID: step === 3 ? Yup.string().required("User ID is required") : null,
    pin: step === 3 ? Yup.string().required("PIN is required") : null,
    // nameOnCard: step === 3 ? Yup.string().required("Name on Card is required") : null,
    rankInUnit: step === 3 ? Yup.string() : null,
    bloodGroup: step === 3 ? Yup.string().required("Blood Group is required") : null,
    // applicationType: step === 3 ? Yup.string().required("Application Type is required") : null,

    // // Step 4 Validation
    // passportFile: step === 4 ? Yup.mixed().required("Passport is required") : null,
    // signatureFile: step === 4 ? Yup.mixed().required("Signature is required") : null,

  });

  console.log(validationSchema)

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      middleName: "",
      // userName: "",
      gender: "",
      dateOfBirth: "",
      password: "",
      confirmPassword: "",
      email: "",
      smNumber:"",
      phoneNo: "",
      alternativePhone: "",
      profession: "",
      address: "",
      userType: "",
      paidStatus: false,
      userID: "",
      nextOfKin:"",
      dateJoined:"",
      unitName:"",
      pin: "",
      nameOnCard: "",
      rankInUnit: "",
      unitNumber:"",
      bloodGroup: "",
      applicationType: "",
      passportFile: "",
      signatureFile: "",
    },
    // validationSchema,
    onSubmit: async (values) => {
      console.log("Form Submitted:", values);
      if (values?.firstName == "") {
        toast.error("First Name is required")
        setStep(1)
      }else if (values?.lastName == ""){
        toast.error("Last Name is required") 
        setStep(1)
      }else if (values?.unitName == ""){
        toast.error("Unit Name is required") 
        setStep(3)
      }else if (values?.pin == ""){
        toast.error("Pin is required") 
        setStep(2)
      }else if (values?.dateOfBirth == ""){
        toast.error("Date of Birth is required") 
        setStep(1)
      }else if (values?.email == ""){
        toast.error("Email is required") 
        setStep(2)
      }else if (values?.rankInUnit == ""){
        toast.error("Last Name is required") 
        setStep(2)
      }else if (values?.phoneNo == ""){
        toast.error("Phone Number is required")
        setStep(2) 
      }else if (values?.profession == ""){
        toast.error("Profession is required") 
        setStep(2)
      }else if (values?.address == ""){
        toast.error("Address is required") 
        setStep(2)
      }else if (signatureFile == null){
        toast.error("Signature is required") 
      }else if (passportFile == null){
        toast.error("Passport is required") 
      }else if (values?.dateJoined == ""){
        toast.error("Date joined is required") 
        setStep(3)
      }else if (values?.nextOfKin == ""){
        toast.error("Next Of Kin is required")
        setStep(3)
      }else if(values?.password !== values?.confirmPassword){
        toast.error("Password does not match")
      } else if (values?.password.length < 8){
        toast.error("Password must be at least 8 characters Alphanumeric")
      }else if (values?.gender == ""){
        toast.error("Gender is required")
        setStep(1)
      } else {
        
        try {
          const response = await axios.post(SIGNUP_URL, {
            // "userName": "NUll",
            "password": values?.password,
            "confirmPassword": values?.confirmPassword,
            "firstname": values?.firstName,
            "profession": values?.profession,
            "designation":values?.designation,
            "profilePictureUrl": values?.passportPhotographUrl,
            "signatureUrl": values?.signatureUrl,
            "passportPhotographUrl": values?.passportPhotographUrl,
            "lastname": values?.lastName,
            "middlename":values?.middleName,
            "smNumber": values?.userID,
            "sex": values?.gender,
            "address": values?.address,
            "doB": values?.dateOfBirth,
            "emailAddress": values?.email,
            "number": values?.phoneNo,
            "paid": true,
            "alternativeNumber": values?.alternativeNumber,
            "userType": "MEMBER",
            "identificationCard": {
              "id": 0,
              // "dateAdded": new Date,
              "addedBy": "string",
              "isActive": true,
              // "dateUpdated": "2025-01-28T05:29:56.312Z",
              // "updatedBy": "string",
              "userId": values?.userID,
              "pin": values?.pin,
              "title": "string",
              "middleName": values?.middleName,
              "nameOnCard": `${values?.firstName} ${values?.middleName} ${values?.lastName}`,
              "rankInUnit": values?.rankInUnit,
              "gender": values?.gender,
              "dob": values?.dateOfBirth,
              "bloodGroup": values?.bloodGroup,
              "email": values?.email,
              "phoneNumber": values?.phoneNo,
              "command": "string",
              "applicationType": "New"
            },
            "isverified": false,
            "unitName":values?.unitName,
            "dateJoined":values?.dateJoined,
            "nextOfKin":values?.nextOfKin,
            "unitNumber": unit?.find((x)=>x?.name == formik.values.unitName)?.number,
  
          });
  
          console.log(response , "response")
          if (response.data) {
            console.log(response?.data?.data , "response data")
            setAuth(response?.data);
            setUserRole(response.data?.data?.role);
            SetUserData(response?.data?.data?.userId)
            // toast.success('Registration successful!');
  
           
            handlePassportUpload()
            handleSignatureUpload()
          }
        } catch (err) {
          if (!err?.response) {
            toast.error('No Server Response');
          } else {
            toast.error('Registration Failed');
          }
        }
      }
      // alert("Registration Successful!");
    },
  });

  const handleNext = () => setStep((prev) => Math.min(prev + 1, 4));
  const handleBack = () => setStep((prev) => Math.max(prev - 1, 1));
  const header=[
    {
      id:1,
      title:"User Information"
    },
    {
      id:2,
      title:"Personal Details"
    },
    {
      id:3,
      title:"Additional Information"
    },
    {
      id:4,
      title:"Upload Documents"
    }
  ]

  // upload files
  
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [fileType, setFileType] = useState("")
  const [fileUrl, SetFileUrl] = useState("")
  // const ApiUrl = localStorage.getItem("ApiUrl")

  const [passportPath, SetPassportPath] = useState("")

  const handlePassportUpload = async (event) => {
    // const selectedFile = event.target.files[0];
    // if (!selectedFile) return;

    const formData = new FormData();
    formData.append("File", passportFileFileitem);
    formData.append("documentType", "PassportPhotograph")
    formData.append("userId", userData)

    toast.promise(
      axios.post(`${ApiUrl}/api/v1/Documents/uploadFile`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadProgress(progress);
        },
      }),
      {
        pending: 'Uploading file...',
        success: 'File uploaded successfully',
        error: 'File upload failed'
      }
    );
    try {
      const response = await axios.post(
        `${ApiUrl}/api/v1/Documents/uploadFile`,
        formData ,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
          },
        }
      );
      console.log("Upload success:", response.data);
      setUploadSuccess(true);
      setUploadProgress(0);
      SetPassportPath(response?.data)
    } catch (error) {
      console.error("Error uploading file:", error);
      // alert("File upload failed. Please try again.");
    }
  };

  const [signatuerPath, setSignaturePath] = useState("")

  const handleSignatureUpload = async (event) => {
    // const selectedFile = event.target.files[0];
    // if (!selectedFile) return;

    const formData = new FormData();
    formData.append("File", signatureFileitem);
    formData.append("documentType", "Signature")

    formData.append("userId", userData)
    console.log(formData)
    // debugger;


    toast.promise(
      axios.post(`${ApiUrl}/api/v1/Documents/uploadFile`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadProgress(progress);
        },
      }),
      {
        pending: 'Uploading file...',
        success: 'File uploaded successfully',
        error: 'File upload failed'
      }
    );
    try {
      const response = await axios.post(
        `${ApiUrl}/api/v1/Documents/uploadFile`,
        formData ,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
          },
        }
      );
      console.log("Upload success:", response.data);
      setUploadSuccess(true);
      setUploadProgress(0);
      setSignaturePath(response?.data)
      navigate('/login')
      localStorage.setItem("url")
    } catch (error) {
      console.error("Error uploading file:", error);
      // alert("File upload failed. Please try again.");
    }
  };
  

  const completeReg = () => {
    toast.success('Account successfully updated');
    // navigate('/login'); // ✅ Correct way to navigate in React Router v6
  };

  const handlePassportAdd = (e) => {
    const file = e.target.files[0]; // Get the first file
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      SetpassportFile(imageUrl); // Set the image URL in state
      SetpassportFileFileitem(file)
    }
  };

  const handleSsignatureAdd = (e) => {
    const file = e.target.files[0]; // Get the first file
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSignatureFile(imageUrl); // Set the image URL in state
      setSignatureFileFileitem(file)
    }
  };

  // console.log(value)
  
  return (
    <div
    // onSubmit={formik.handleSubmit} 
    className=" w-full lg:w-[70vw] xl:w-[55vw] flex items-center justify-center p-4">
      <div className="bg-white  xl:my-0 w-full h-full xl:h-[700px] rounded-lg lg:px-[100px] xl:px-[150px] shadow-lg p-6">
       
        <div className=" hidden md:flex my-5 w-full justify-around mb-10">
          {
            header.map((item)=>(
              <div key={item?.id} className=" flex flex-col justify-center items-center gap-5">
                    <h2 className={` bg-gray-400 ${step === item?.id &&("!bg-gray-700")} w-[30px] text-white rounded-full px-3 py-1`}>{item?.id}</h2>
                    <p className=" text-black font-normal text-[10px] xl:text-[12px]">{item?.title}</p>
                   
              </div>
            ))
          }
        </div>

        <div className="flex md:hidden my-5 w-full justify-around mb-10">
          {
              <div className=" flex flex-col justify-center items-center gap-5">
                    <h2 className={` !bg-gray-700 w-[30px] text-white rounded-full px-3 py-1`}>{step}</h2>
                    <p className=" text-black font-normal text-[10px] xl:text-[12px]">{
                      header.find((x)=>x.id == step)?.title
                      }</p>
                   
              </div>
          }
        </div>
        <div>
        {step === 1 && (
            <div className="  grid grid-cols-1 lg:grid-cols-2 xl:gap-x-16 xl:gap-y-3 gap-x-5">
              {["firstName", "lastName", "middleName"].map((field) => (
                <div key={field} className="mb-4">
                  <label className="block mb-1 text-black font-semibold capitalize">{field.replace(/([a-z])([A-Z])/g, "$1 $2")}</label>
                  <input
                    type="text"
                    name={field}
                    value={formik.values[field]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={`Enter ${field.replace(/([a-z])([A-Z])/g, "$1 $2")}`}
                    className="w-full border px-4 py-2 rounded"
                    required
                  />
                  {formik.touched[field] && formik.errors[field] && (
                    <p className="text-red-500 text-sm">{formik.errors[field]}</p>
                  )}
                </div>
              ))}
              <div className="mb-4">
                <label className="block mb-1">Gender</label>
                <select
                  name="gender"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  className="w-full border px-4 py-2 rounded"
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
                {formik.touched.gender && formik.errors.gender && (
                  <p className="text-red-500 text-sm">{formik.errors.gender}</p>
                )}
              </div>
              <div className="mb-4">
                <label className="block mb-1">Date of Birth</label>
                <input
                  type="date"
                  name="dateOfBirth"
                  value={formik.values.dateOfBirth}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full border px-4 py-2 rounded"
                />
                {formik.touched.dateOfBirth && formik.errors.dateOfBirth && (
                  <p className="text-red-500 text-sm">{formik.errors.dateOfBirth}</p>
                )}
              </div>
              <div className="mb-4">
                <label className="block mb-1">Password</label>
                <input
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  className="w-full border px-4 py-2 rounded"
                />
                 {
            formik.values?.password && !/^(?=.*[a-zA-Z])(?=.*\d).+$/.test(formik.values.password) && (
              <p className="text-red-500 text-sm">Password must contain both letters and numbers 8 characters long</p>
            )
          }
          {formik.touched.password && formik.errors.password && (
            <p className="text-red-500 text-sm">{formik.errors.password}</p>
          )}
                {formik.touched.password && formik.errors.password && (
                  <p className="text-red-500 text-sm">{formik.errors.password}</p>
                )}
              </div>
              <div className="mb-4">
                <label className="block mb-1">Confirm Password</label>
                <input
                  type="password"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  className="w-full border px-4 py-2 rounded"
                />
                {
                    formik.values?.password !== formik.values?.confirmPassword && (
                      <p className="text-red-500 text-sm">Password does not match</p>
                    )
                    
                  }
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                  <p className="text-red-500 text-sm">{formik.errors.confirmPassword}</p>
                )}
              </div>
            </div>
          )}

          
         
          {/* step 2 */}
          {step === 2 && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 xl:gap-x-16">
            {["email", "pin", "phoneNo", "alternativePhone", "profession"].map((field) => (
              <div key={field} className="mb-4">
                <label className="block mb-1 capitalize">
                  {field === "phoneNo" ? "Phone Number" : 
                    field === "alternativePhone" ? "Alternative Phone Number" : 
                    field === "pin" ? "PIN" : field}
                </label>
                <input
                  type={field === "phoneNo" || field === "alternativePhone" ? "tel" : "text"}
                  name={field}
                  value={formik.values[field]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  className="w-full border px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {formik.touched[field] && formik.errors[field] && (
                  <p className="text-red-500 text-sm mt-1">{formik.errors[field]}</p>
                )}
              </div>
            ))}

            <div className="mb-4">
              <label className="block mb-1 capitalize">
                Rank in Unit
              </label>
              <select
                name="rankInUnit"
                value={formik.values.rankInUnit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
                className="w-full border px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select Rank</option>
                {roles?.map((role) => (
                  <option key={role?.id} value={role?.name}>{role?.name}</option>
                ))}
              </select>
              {formik.touched.rankInUnit && formik.errors.rankInUnit && (
                <p className="text-red-500 text-sm mt-1">{formik.errors.rankInUnit}</p>
              )}
            </div>

            <div className="mb-4 md:col-span-2">
              <label className="block mb-1 capitalize">Address</label>
              <input
                type="text"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
                className="w-full border px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.address && formik.errors.address && (
                <p className="text-red-500 text-sm mt-1">{formik.errors.address}</p>
              )}
            </div>
          </div>
        )}

    {/* step3 */}
    {step === 3 && (
      <div className="  grid grid-cols-1 lg:grid-cols-2 xl:gap-x-16 xl:gap-y-3 gap-x-5">
        
        <div className="mb-4">
          <label className="block mb-1">Blood Group</label>
          <select
            name="bloodGroup"
            value={formik.values.bloodGroup}
            onChange={formik.handleChange}
            required
            onBlur={formik.handleBlur}
            className="w-full border px-4 py-2 rounded"
          >
            <option value="">Select Blood Group</option>
            <option value="A+">A+</option>
            <option value="A-">A-</option>
            <option value="B+">B+</option>
            <option value="B-">B-</option>
            <option value="AB+">AB+</option>
            <option value="AB-">AB-</option>
            <option value="O+">O+</option>
            <option value="O-">O-</option>
          </select>
          {formik.touched.bloodGroup && formik.errors.bloodGroup && (
            <p className="text-red-500 text-sm">{formik.errors.bloodGroup}</p>
          )}

          
        </div>

        {["unitName",].map((field) => (
              <div  key={field} className={` mb-4 ${field == "address" &&(`col-span-2`) } `}>
                <label className="block mb-1 capitalize">
                  {field === "nextOfKin" ? "Next of KIn" : field === "unitName" ? "Unit Name" : field ==="unitNumber" ? "Unit Number" : field == "dateJoined" ? "Date Joined" :field}
                </label>
                <select
                  name="unitName"
                  value={formik.values.unitName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  className="w-full border px-4 py-2 rounded"
                >
                  <option value="">Select Unit</option>
                  {
                    unit?.map((x, index)=>(
                    <option key={x?.id} value={x?.name} >{x?.name}</option>
                    ))
                  }
                </select>
                {formik.touched[field] && formik.errors[field] && (
                  <p className="text-red-500 text-sm">{formik.errors[field]}</p>
                )}
              </div>
            ))}

        {["unitNumber",].map((field) => (
              <div  key={field} className={` mb-4 ${field == "address" &&(`col-span-2`) } `}>
                <label className="block mb-1 capitalize">
                  {field === "nextOfKin" ? "Next of KIn" : field === "unitName" ? "Unit Name" : field ==="unitNumber" ? "Unit Number" : field == "dateJoined" ? "Date Joined" : "nextOfKin" ? "":field}
                </label>
                <p className="w-full border px-4 py-2 rounded">
                  {
                  unit?.find((x)=>x?.name == formik.values.unitName)?.number
                  }
                </p>
               
              </div>
            ))}

{["dateJoined"].map((field) => (
              <div  key={field} className={` mb-4 ${field == "address" &&(`col-span-2`) } `}>
                <label className="block mb-1 capitalize">
                  {field === "nextOfKin" ? "Next of KIn" : field === "unitName" ? "Unit Name" : field ==="unitNumber" ? "Unit Number" : field == "dateJoined" ? "Date Joined" : "nextOfKin" ? "":field}
                </label>
                <input
                  type="month"
                  name={field}
                  value={formik.values[field]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  className="w-full border px-4 py-2 rounded"
                />
                {formik.touched[field] && formik.errors[field] && (
                  <p className="text-red-500 text-sm">{formik.errors[field]}</p>
                )}

              </div>
            ))}

        {["nextOfKin"].map((field) => (
              <div  key={field} className={` mb-4 ${field == "nextOfKin" &&(`col-span-2`) } `}>
                <label className="block mb-1 capitalize">
                  {field === "nextOfKin" ? "Next of KIn" : field === "unitName" ? "Unit Name" : field ==="unitNumber" ? "Unit Number" : field == "dateJoined" ? "Date Joined" : "nextOfKin" ? "":field}
                </label>
                <input
                  type="text"
                  name={field}
                  value={formik.values[field]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  className="w-full border px-4 py-2 rounded"
                />
                {formik.touched[field] && formik.errors[field] && (
                  <p className="text-red-500 text-sm">{formik.errors[field]}</p>
                )}
              </div>
            ))}
          
        
      </div>
    )}


    {/* step4 */}
  {/* Step 4: Passport and Signature Upload */}
  {step === 4 && (
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:gap-x-16 xl:gap-y-3 gap-x-5">
            {/* Passport Upload */}
            <div className="mb-6">
              <label className="block mb-2 text-lg font-medium">Passport Upload</label>
              <div
                className="w-full h-40 flex flex-col justify-center items-center border-2 border-dashed rounded-lg hover:border-blue-500 transition-all cursor-pointer"
                onClick={() => document.getElementById("passport-upload").click()}
              >
                <FiUpload className="text-3xl text-gray-400" />
                <p className="mt-2 text-gray-500 text-sm">Click to upload passport</p>
              </div>
              <input
                id="passport-upload"
                type="file"
                required
                name="passportFile"
                // accept="image/*"
                className="hidden"
                onChange={handlePassportAdd}
              />
              {passportFile && <img src={passportFile} alt="Uploaded Passport" className="mt-[-150px] mr-16 h-40 w-auto rounded-lg" />}
              {formik.touched.passportFile && formik.errors.passportFile && (
            <p className="text-red-500 text-sm">{formik.errors.passportFile}</p>
          )}
            </div>

            {/* Signature Upload */}
            <div className="mb-6">
              <label className="block mb-2 text-lg font-medium">Signature Upload</label>
              <div
                className="w-full h-40 flex flex-col justify-center items-center border-2 border-dashed rounded-lg hover:border-blue-500 transition-all cursor-pointer"
                onClick={() => document.getElementById("signature-upload").click()}
              >
                <FiUpload className="text-3xl text-gray-400" />
                <p className="mt-2 text-gray-500 text-sm">Click to upload signature</p>
              </div>
              <input
                id="signature-upload"
                type="file"
                name="signatureFile"
                // accept="image/*"
                className="hidden"
                required
                onChange={handleSsignatureAdd}
              />
              {signatureFile && <img src={signatureFile} alt="Uploaded Signature" className="mt-[-150px] mr-16 h-40 w-auto rounded-lg" />}
            </div>
          </div>
        )}


          {/* Navigation Buttons */}
          <div className={`flex justify-between mt-4 `}>
            {step > 1 && (
              <button
                type="button"
                onClick={handleBack}
                className="px-4 py-2 bg-gray-300 w-[150px] text-gray-800 rounded-lg hover:bg-gray-400"
              >
                Back
              </button>
            )}
            {step < 4 ? (
              <div
                type="button"
                onClick={handleNext}
                className={`px-4 py-2 bg-blue-500 w-[150px] ${step === 1 &&( `!w-full`)} text-white text-center rounded-md hover:bg-blue-600`}
              >
                {step === 1 ? "Continue" : "Next"}
              </div>
            ) : (
              <button
                // type="submit"
                onClick={formik.handleSubmit}
                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
              >
                Submit
              </button> 
            )}
            
          </div>
          {/* {
              step ==4 &&(
                <div className=" flex w-full justify-between">
                    <button
                    type="button"
                    onClick={handleBack}
                    className="px-4 py-2 bg-gray-300 w-[150px] text-gray-800 rounded-lg hover:bg-gray-400"
                  >
                    Back
                  </button>
                    <div
                    // type="submit"
                    onClick={completeReg}
                    className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                  >
                    Submit
                  </div> 
              </div>)
            } */}
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
