import React, { useState } from "react";
import { CiEdit } from "react-icons/ci";
import { FiTrash2 } from "react-icons/fi";
import { IoEyeOutline } from "react-icons/io5";
import "./Table.css";
import Loader from "./Loader";
import useWindowSize from "../../hooks/useWindowSize";

function Table({
  header,
  data,
  options,
  openModal,
  setOpenModal,
  loading,
  rowData,
  setRowData,
  searchQuery,
  state
}) {
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const windowSize = useWindowSize();

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const filteredData = searchQuery
    ? data?.filter((item) =>
        Object?.values(item)?.some((value) =>
          value?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())
        )
      )
    : data;

  const sortedData = filteredData?.slice()?.sort((a, b) => {
    if (!sortBy) return 0;
    return sortOrder === "asc"
      ? a[sortBy]?.localeCompare(b[sortBy])
      : b[sortBy]?.localeCompare(a[sortBy]);
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData?.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="max-w-[100vw] overflow-x-scroll">
      <table className="table overflow-x-auto">
        {/* Always render the table header */}
        <thead className={options?.variant === "primary" ? "tablePrimaryBg" : "bg-white text-black"}>
          <tr>
            {header?.map((item, index) => (
              <th
                key={index}
                className="font-semibold cursor-pointer w-fit"
                style={{ width: "auto", minWidth: "120px" }}
                onClick={() => options?.allowSorting && handleSort(item.identifier)}
              >
                {item.name} {sortBy === item.identifier && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
            ))}
            {options?.allowActions && <th>Actions</th>}
          </tr>
        </thead>
  
        {/* Conditional rendering for the table body */}
        {state ? (
          <tbody>
            <tr>
              <td colSpan={header.length + (options?.allowActions ? 1 : 0)} className="text-center !w-full col-span-3 justify-items-center !justify-center bg-white py-10">
                <Loader color="#0D6EFD" />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {currentItems?.length > 0 ? (
              currentItems.map((row, rowIndex) => (
                <tr key={rowIndex} className={` ${row?.isVerified === false ? " !line-through" : ""}  ${options?.tableStyle === "striped-even" ? "even:bg-slate-200" : "bg-white"}`}>
                  {header.map((column, colIndex) => (
                    <td  key={colIndex}>{row[column.identifier]}</td>
                  ))}
                  {options?.allowActions && (
                    <td className="flex items-center justify-center gap-3">
                      {options?.actions?.edit && (
                        <span
                          className="w-[2rem] h-[2rem] flex justify-center items-center rounded-full bg-blue-100 cursor-pointer"
                          onClick={() => {
                            setOpenModal?.({ modalName: "edit", showModal: true });
                            setRowData?.(row);
                          }}
                        >
                          <CiEdit size={16} />
                        </span>
                      )}
                      {options?.actions?.delete && (
                        <span
                          className="w-[2rem] h-[2rem] flex justify-center items-center rounded-full bg-red-100 cursor-pointer"
                          onClick={() => {
                            setOpenModal?.({ modalName: "delete", showModal: true });
                            setRowData?.(row);
                          }}
                        >
                          <FiTrash2 size={14} color="red" />
                        </span>
                      )}
                      {options?.actions?.view && (
                        <span
                          className="w-[2rem] h-[2rem] flex justify-center items-center rounded-full bg-blue-300 cursor-pointer"
                          onClick={() => {
                            setOpenModal?.({ modalName: "view", showModal: true });
                            setRowData?.(row);
                          }}
                        >
                          <IoEyeOutline size={15} />
                        </span>
                      )}
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={header.length + (options?.allowActions ? 1 : 0)} className="text-center py-10 bg-white">
                  No Results Found
                </td>
              </tr>
            )}
          </tbody>
        )}

      </table>
        

        {options?.showPagination && (
      <div className="mt-4 flex justify-between items-center">
        {/* Items Per Page Selection */}
        <div className="flex items-center gap-2">
          <label className="text-[15px]">Items Per Page</label>
          <select
            className="bg-white p-2 rounded-md text-[13px]"
            value={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(Number(e.target.value));
              setCurrentPage(1); // Reset to page 1 when items per page changes
            }}
          >
            {[10, 20, 30, 40, 50].map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
        </div>

        {/* Pagination Buttons */}
        {data?.length > itemsPerPage && (
          <div className="flex gap-2">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="bg-gray-200 px-3 py-1 rounded disabled:opacity-50"
            >
              Previous
            </button>

            {[...Array(Math.ceil(data.length / itemsPerPage))].map((_, index) => {
              const totalPages = Math.ceil(data.length / itemsPerPage);
              const maxPagesToShow = 5;
              let startPage = Math.max(0, currentPage - Math.floor(maxPagesToShow / 2));
              let endPage = Math.min(totalPages, startPage + maxPagesToShow);

              if (index >= startPage && index < endPage) {
                return (
                  <button
                    key={index}
                    onClick={() => setCurrentPage(index + 1)}
                    className={`px-3 py-1 rounded ${
                      currentPage === index + 1 ? "bg-blue-500 text-white" : "bg-gray-200"
                    }`}
                  >
                    {index + 1}
                  </button>
                );
              }
              return null;
            })}

            <button
              onClick={() =>
                setCurrentPage((prev) =>
                  prev < Math.ceil(data.length / itemsPerPage) ? prev + 1 : prev
                )
              }
              disabled={currentPage >= Math.ceil(data.length / itemsPerPage)}
              className="bg-gray-200 px-3 py-1 rounded disabled:opacity-50"
            >
              Next
            </button>
          </div>
        )}
      </div>
    )}

    </div>
  );
  
}

export default Table;
